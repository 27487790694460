import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const taskVuex = createNamespacedHelpers("task");
const dailyVuex = createNamespacedHelpers("daily");
export default {
  name: "earn",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting']),
    ...userVuex.mapState(["userData"]),
    ...taskVuex.mapState(["taskList", "advList", "selectTab"]),
    ...dailyVuex.mapState(["summary"])
  },
  data() {
    return {
      popUpGoldShow: false,
      popCoinHeroShow: false,
      popBindWalletShow: false,
      popBindOkxWalletShow: false,
      taskNum: 0,
      extraTaskNum: 0,
      checkNum: 0,
      timeVal: null,
      stimeVal: null,
      popEarnTipShow: false,
      isPopRewardResultShow: false,
      itemData: {},
      selectRow: {},
      iframeShow: false,
      iframeUrl: ""
    };
  },
  beforeRouteLeave(to, from, next) {
    clearTimeout(this.timeVal);
    clearTimeout(this.stimeVal);
    this.popBindWalletShow = false;
    this.popBindOkxWalletShow = false;
    this.iframeShow = false;
    this.$nextTick(() => {
      next();
    });
  },
  watch: {
    "userData": {
      handler() {
        this.checkTask();
      },
      deep: true,
      immediate: true
    },
    "taskList": {
      handler(newVal, oldVal) {
        console.log("this.taskList", this.taskList);
        this.checkTask();
      },
      deep: true,
      immediate: true
    },
    "advList": {
      handler(newVal, oldVal) {
        console.log("this.advList", this.advList);
        this.checkTask();
      },
      deep: true,
      immediate: true
    },
    "selectTab": {
      async handler(newVal, oldVal) {
        if (newVal == 1) {
          this.getSummary();
        }
      },
      deep: true,
      immediate: true
    }
  },
  async mounted() {
    if (this.taskList.length == 0) await this.getTaskList();

    //if(this.advList.length == 0)
    await this.getAdvList();
    this.countDown();
    if (this.selectTab == 1) {
      this.getSummary();
    }
  },
  methods: {
    ...walletVuex.mapActions(["loadTaskCount"]),
    ...userVuex.mapMutations(['setUserData']),
    ...taskVuex.mapActions(['getTaskList', 'getAdvList']),
    ...taskVuex.mapMutations(['setSelectTab']),
    ...dailyVuex.mapActions(['getSummary']),
    onUpGold() {
      this.popUpGoldShow = true;
    },
    onCoinHero() {
      this.popCoinHeroShow = true;
    },
    onBindWallet() {
      // this.$nextTick(() => {
      //   this.tonWallet.openOrCloseTonModal(true);
      // });
      // if(this.userData.bind_wallet) return
      this.popBindWalletShow = true;
    },
    async onJoin(type, url, extra) {
      console.log("url=====", url);
      if (type == 'telegram' || type == 'channel') {
        this.WebApp.openTelegramLink(url);
      } else {
        this.WebApp.openLink(url);
      }
      if (!this.userData[`joined_${type}`]) {
        this.doCheckJoin(type);
      }
      // this.$loading.show();
      // setTimeout(()=>{
      //   this.$loading.hide();
      // },1000)
    },

    async doCheckJoin(type) {
      if (this.checkNum >= 5) {
        this.checkNum = 0;
        return;
      }
      this.checkNum++;
      let rs = await this.$http.post("/my/social/join", {
        target: type
      });
      if (rs.code == 0) {
        let newUserData = {
          ...this.userData,
          ...(rs.data.userInfo ? rs.data.userInfo : rs.data)
        };
        this.setUserData(newUserData);
        this.loadTaskCount();
      } else if (rs.code == 302) {
        setTimeout(() => {
          this.doCheckJoin(type);
        }, 2000);
      }
    },
    onAccept() {
      this.isPopRewardResultShow = false;
    },
    async onExtralJoin(item, adv = 0) {
      console.log("url=====", item.url);
      if ((item.type || '') == 'adsgram' || (item.provider || '') == 'adsgram') {
        this.$loading.show();
        this.selectRow = {
          value: item.reward,
          unit: this.setting.coin,
          type: "tapcoins"
        };
        const AdController = await window.Adsgram.init({
          blockId: item.url
        });
        await AdController.show().then(result => {
          // user watch ad till the end
          // your code to reward user
          this.$loading.hide();
          if (result.done && !item.completed) this.checkExtralTask(item, adv);
        }).catch(result => {
          // user skipped video or get error during playing ad
          // do nothing or whatever you want
          console.log(result);
          this.$loading.hide();
        });
        this.$loading.hide();
        return;
      } else if ((item.type || '') == 'tmads' || (item.provider || '') == 'tmads') {
        this.$loading.show();
        this.selectRow = {
          value: item.reward,
          unit: this.setting.coin,
          type: "tapcoins"
        };
        TMAds.init({
          app_key: item.url,
          init_data: this.WebApp.initData,
          init_data_unsafe: this.WebApp.initDataUnsafe
        });
        TMAds.showInterstitialAd().then(() => {
          //interstitial ad has been successfully shown and closed
          this.$loading.hide();
          if (!item.completed) this.checkExtralTask(item, adv);
        }).catch(() => {
          //interstitial ad failed to show (likely due to the network issues)
          this.$loading.hide();
        });
        return;
      } else if ((item.type || '') == 'matrix') {
        if (!item.completed) {
          this.$router.push(`/taskDetail?id=${item.id}`);
        }
        return;
      } else if ((item.type || '') == 'adcloud' || (item.provider || '') == 'adcloud') {
        if (!item.completed) {
          this.$loading.show();
          this.itemData = item;
          this.itemData.adv = adv;
          this.iframeUrl = item.url;
          this.iframeShow = true;
          this.$nextTick(() => {
            this.$set(this.itemData, 'adv_countdown', 20);
            this.$refs.iframDiv.style.top = '0px';
            this.advCountDown();
            this.WebApp.BackButton.hide();
          });
          this.$loading.hide();
        }
        return;
      } else if ((item.type || '') == 'page') {
        if (item.target == 'BindOkxWallet') {
          if (!item.completed) {
            this.itemData = item;
            this.popBindOkxWalletShow = true;
          }
        } else {
          //if(!item.completed)
          this.$router.push(`/${item.target}?id=${item.id}`);
        }
        return;
      } else if (item.type == 'telegram' || item.type == 'channel') {
        //this.WebApp.openTelegramLink(item.url)
      } else {
        //this.WebApp.openLink(item.url)
      }
      this.itemData = item;
      this.itemData.adv = adv;
      this.selectRow = {
        value: item.reward,
        unit: this.setting.coin,
        type: "tapcoins"
      };
      this.popEarnTipShow = true;
      // this.checkNum = 0;
      // if(!item.completed){
      //   item.completed = -1
      //   setTimeout(()=>{
      //     this.checkExtralTask(item,adv);
      //   },10000)
      // }
    },

    async checkExtralTask(item, adv) {
      if (this.checkNum >= 5) {
        this.checkNum = 0;
        return;
      }
      this.checkNum++;
      let rs = await this.$http.post("/task/complete", {
        taskId: item.id,
        adv: adv
      });
      if (rs.code == 0) {
        item.completed = 1;
        if (adv == 1 && item.cyclical == 1) {
          item.countdown = item.cycle_interval;
        }
        let newUserData = {
          ...this.userData,
          ...(rs.data.userInfo ? rs.data.userInfo : rs.data)
        };
        this.setUserData(newUserData);
        this.isPopRewardResultShow = true;
        if (adv == 0) this.loadTaskCount();
      } else if (rs.code == 302) {
        setTimeout(() => {
          this.checkExtralTask(item, adv);
        }, 2000);
      }
    },
    checkTask() {
      this.taskNum = 0;
      this.extraTaskNum = 0;
      if ((this.setting.reward.bindWallet || this.setting.reward.bindWalletStock) && !this.userData.bind_wallet) this.taskNum += 1;
      for (let key in this.setting.socials) {
        if (this.setting.socials[key]) {
          switch (key) {
            case "channel":
              if (this.userData.joined_channel == 0) {
                this.taskNum += 1;
              }
              break;
            case "discord":
              if (this.userData.joined_discord == 0) {
                this.taskNum += 1;
              }
              break;
            case "telegram":
              if (this.userData.joined_telegram == 0) {
                this.taskNum += 1;
              }
              break;
            case "twitter":
              if (this.userData.joined_twitter == 0) {
                this.taskNum += 1;
              }
              break;
          }
        }
      }
      this.taskList.map(item => {
        if (item.completed == 0) {
          if (item.is_official == 0) this.extraTaskNum += 1;else if (item.is_official == 1) this.taskNum += 1;
        }
      });
      this.advList.map(item => {
        if (item.completed == 0) {
          this.taskNum += 1;
        }
      });
    },
    onSkip(page) {
      this.$router.push(`/${page}`);
    },
    onTab(t) {
      this.setSelectTab(t);
    },
    countDown() {
      clearTimeout(this.timeVal);
      this.advList.map(item => {
        if (item.cyclical == 1 && item.completed == 1) {
          item.countdown = item.countdown - 1;
          if (item.countdown <= 0) {
            item.completed = 0;
          }
        }
      });
      this.timeVal = setTimeout(() => {
        this.countDown();
      }, 1000);
    },
    advCountDown() {
      clearTimeout(this.stimeVal);
      this.itemData.adv_countdown = this.itemData.adv_countdown - 1;
      if (this.itemData.adv_countdown <= 0) {
        this.itemData.completed = 1;
        this.checkExtralTask(this.itemData, this.itemData.adv);
      } else {
        this.stimeVal = setTimeout(() => {
          this.advCountDown();
        }, 1000);
      }
    },
    onLucky() {
      this.$router.push(`/youtube?id=${this.setting.lucky_code_id}`);
    },
    closeIframDiv() {
      if (this.itemData.adv_countdown > 0) return;
      this.WebApp.BackButton.show();
      this.iframeShow = false;
    }
  }
};