/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./walletDetail.vue?vue&type=template&id=ad487fb8&scoped=true"
import script from "./walletDetail.vue?vue&type=script&lang=js"
export * from "./walletDetail.vue?vue&type=script&lang=js"
import style1 from "./walletDetail.vue?vue&type=style&index=1&id=ad487fb8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad487fb8",
  null
  
)

export default component.exports