import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const bioMatrixScanVuex = createNamespacedHelpers("bioMatrixScan");
//import {v5 as uuidv5} from 'uuid';
export default {
  name: "bioMatrixScan",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting']),
    ...userVuex.mapState(["userData"]),
    ...bioMatrixScanVuex.mapState(['showPop', 'taskInfo'])
  },
  data() {
    return {
      popBioMatrixScanShow: false,
      popBioMatrixScanCheckShow: false
    };
  },
  beforeRouteLeave(to, from, next) {
    this.setShowPop(this.popBioMatrixScanShow);
    this.popBioMatrixScanShow = false;
    this.popBioMatrixScanCheckShow = false;
    this.$nextTick(() => {
      next();
    });
  },
  watch: {},
  async mounted() {
    if (this.showPop) {
      this.popBioMatrixScanShow = this.showPop; // 恢复弹窗状态
      this.setShowPop(false);
    } else {
      this.setStartTime(0);
    }
    if (!this.taskInfo) {
      await this.getTaskInfo();
    }
  },
  methods: {
    ...userVuex.mapMutations(['setUserData']),
    ...bioMatrixScanVuex.mapMutations(['setShowPop', 'setStartTime']),
    ...bioMatrixScanVuex.mapActions(['getTaskInfo']),
    doChecked(url) {
      if (!this.taskInfo) {
        return;
      }
      if (this.taskInfo.completed_count >= 1) {
        this.$router.push(url);
      } else {
        this.popBioMatrixScanCheckShow = true;
      }
    },
    jumpTo(url, show) {
      this.popBioMatrixScanCheckShow = false;
      if (show && this.taskInfo.completed_count == 0) {
        // if(this.taskInfo.completed_count >= 1){
        //   return
        // }
        // if(this.taskInfo.completed_count >= 100){
        //   return
        // }
        this.setStartTime(Date.now());
        this.popBioMatrixScanShow = true;
      }
      this.$router.push(url);
    }
    // backTaskList(){
    //   this.$router.push('/earn');
    // }
  }
};