var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "scanTest page",
    class: _vm.isMobile && 'wap',
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/task-detail-bg.jpg`)})`
    }
  }, [_c('button', {
    staticClass: "btn",
    on: {
      "click": _vm.openIF
    }
  }, [_vm._v("Start Scan")]), _c('div', [_vm._v(" " + _vm._s(_vm.result.data) + " ")]), _c('button', {
    staticClass: "btn btn1",
    on: {
      "click": _vm.checkSF
    }
  }, [_vm._v("Check")]), _c('button', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.onClose();
      }
    }
  }, [_vm._v("关闭")])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };