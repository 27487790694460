import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "popBioMatrixScan",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "tonConnect", "setting"])
  },
  data() {
    return {
      bioChecked: false
    };
  },
  watch: {
    "$parent.popBioMatrixScanCheckShow": {
      handler(bool) {
        if (bool) {
          this.checked = false;
        }
      },
      deep: true,
      immediate: true
    }
  },
  async mounted() {},
  methods: {
    close() {
      this.$parent.popBioMatrixScanCheckShow = false;
    },
    submit() {
      if (this.bioChecked) {
        this.$parent.jumpTo('/bioMatrixScanDetail', 1);
      }
    }
  }
};