var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    ref: "myScroll",
    staticClass: "bitgetwalletScanInvite page",
    class: _vm.isMobile && 'wap',
    on: {
      "scroll": _vm.checkScroll
    }
  }, [_c('div', {
    staticClass: "top-txt"
  }, [_vm._v(" Invite more friends to complete verification and share a $1,000,000 USDT prize pool. ")]), _c('div', {
    staticClass: "invite-box"
  }, [_c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "num"
  }, [_c('div', {
    staticClass: "item-tip"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/usdt.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.currentData.threshold) + " ")]), _c('div', {
    staticClass: "item-tip-txt"
  }, [_c('div', [_vm._v("Invited " + _vm._s(_vm.currentData.value * 10) + " / " + _vm._s(_vm.currentData.threshold * 10))])])]), _vm.currentData ? _c('div', {
    staticClass: "line"
  }, [_c('div', {
    staticClass: "has",
    class: _vm.rate > 98 && 'max',
    style: {
      'width': _vm.rate + '%'
    }
  })]) : _vm._e()]), _c('ul', {
    staticClass: "items"
  }, [_vm._l(_vm.itemList, function (item, index) {
    return _c('li', {
      staticClass: "item"
    }, [_c('div', {
      staticClass: "bd"
    }, [_c('div', {
      staticClass: "bg",
      class: item.reached && 'actived'
    }, [_c('div', {
      staticClass: "txt"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/usdt.png`),
        "alt": ""
      }
    }), _c('span', [_vm._v(_vm._s(item.threshold))])])])]), item.current ? _c('div', {
      staticClass: "claim-btn",
      class: !item.reached && 'gray',
      on: {
        "click": function ($event) {
          return _vm.claim(item, index);
        }
      }
    }, [_vm._v(" Claim ")]) : _vm._e()]);
  }), _c('li', {
    staticClass: "more"
  }, [_vm._v(" MORE ")])], 2)]), _c('div', {
    staticClass: "invite-btns"
  }, [_c('button', {
    staticClass: "invite-btn",
    on: {
      "click": _vm.onShare
    }
  }, [_vm._v("Invite")]), _c('div', {
    directives: [{
      name: "copy",
      rawName: "v-copy",
      value: `https://t.me/${_vm.setting.botname}/app?startapp=ref_${_vm.userData.ref_code}-page_bioMatrixScan`,
      expression: "`https://t.me/${setting.botname}/app?startapp=ref_${userData.ref_code}-page_bioMatrixScan`"
    }],
    staticClass: "copy"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-copy"
    }
  })], 1)]), _vm._m(0), _c('div', {
    staticClass: "tabs"
  }, [_c('div', {
    staticClass: "tab",
    class: _vm.selectTab == 1 && 'active'
  }, [_vm._v(" My Records ")]), _c('img', {
    staticClass: "proof",
    attrs: {
      "src": require(`@images/${_vm.theme}/proof.jpg`),
      "alt": ""
    },
    on: {
      "click": _vm.jumpToProof
    }
  })]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectTab == 0,
      expression: "selectTab == 0"
    }],
    staticClass: "bonus"
  }, [_c('div', {
    staticClass: "bonus-left"
  }, [_vm._v(" Bonus Received "), _c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/usdt.png`),
      "alt": ""
    }
  }), _c('span', {
    staticClass: "num"
  }, [_vm._v(_vm._s(_vm._f("toThousands")(_vm.rankingTotal.toFixed(2))))])]), _c('img', {
    staticClass: "proof",
    attrs: {
      "src": require(`@images/${_vm.theme}/proof.jpg`),
      "alt": ""
    },
    on: {
      "click": _vm.jumpToProof
    }
  })]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectTab == 0,
      expression: "selectTab == 0"
    }],
    staticClass: "rankings-list"
  }, _vm._l(_vm.rankingList, function (item, index) {
    return _c('div', {
      staticClass: "rankings-item"
    }, [_c('div', {
      staticClass: "head"
    }, [_c('m-head', {
      attrs: {
        "name": item,
        "bgColor": item.head_color
      }
    }), _c('span', [_vm._v(_vm._s(item.firstname) + " " + _vm._s(item.lastname))])], 1), _c('div', {
      staticClass: "num"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/usdt.png`),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(_vm._f("toThousands")(item.reward_amount.toFixed(2))) + " ")])]);
  }), 0), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectTab == 1,
      expression: "selectTab == 1"
    }],
    staticClass: "records-list"
  }, _vm._l(_vm.logList, function (item, index) {
    return _c('div', {
      staticClass: "records-item"
    }, [_c('m-head', {
      attrs: {
        "name": item,
        "bgColor": item.head_color
      }
    }), _c('span', [_vm._v(_vm._s(item.firstname) + " " + _vm._s(item.lastname))])], 1);
  }), 0)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "desc-box"
  }, [_c('ul', [_c('li', [_vm._v("All uncertified new and old players can be certified through my invitation.")]), _c('li', [_vm._v(" When a player completes the certification through my invitation link.lwill receive a reward. ")]), _c('li', [_vm._v(" So far, there are more than 10 million old players who have not completed the certification, and more new players continue to join TapCoins. ")])])]);
}];
export { render, staticRenderFns };