var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "width": "90%",
      "height": "auto",
      "is-loc": "center"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("You only have one chance to verify, please verify with an unverified face")]), _c('div', {
    staticClass: "desc"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.bioChecked,
      expression: "bioChecked"
    }],
    staticClass: "ck",
    attrs: {
      "type": "checkbox",
      "id": "check_txt"
    },
    domProps: {
      "checked": Array.isArray(_vm.bioChecked) ? _vm._i(_vm.bioChecked, null) > -1 : _vm.bioChecked
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.bioChecked,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.bioChecked = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.bioChecked = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.bioChecked = $$c;
        }
      }
    }
  }), _c('label', {
    attrs: {
      "for": "check_txt"
    }
  }, [_vm._v("I will use an unverified face for verification")])]), _c('div', {
    staticClass: "btns",
    on: {
      "click": _vm.submit
    }
  }, [_c('button', {
    staticClass: "btn",
    class: !_vm.bioChecked && 'gray'
  }, [[_vm._v("Verify")]], 2)])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };