var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "width": "95%",
      "height": "auto",
      "is-loc": "center"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_vm.taskInfo ? _c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "energy__img",
    class: {
      enery__img__square: _vm.type == 1
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.taskInfo.icon,
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "energy__title"
  }, [_vm._v(" Profit per hour "), _c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/airdrop-tap-icon6.png`),
      "alt": ""
    }
  }), _c('i', [_vm._v("+" + _vm._s(_vm.taskInfo.reward_pph))])]), _c('div', {
    staticClass: "boo__num"
  }, [_c('div', {
    staticClass: "boo__num__coin"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/tickets.png`),
      "alt": ""
    }
  }), _vm._v(_vm._s(_vm.taskInfo.completion_count)), _c('span', [_vm._v("/" + _vm._s(_vm.taskInfo.limit_count))])])]), _c('div', {
    staticClass: "energy__subtitle"
  }, [_vm._v(" " + _vm._s(_vm.taskInfo.description) + " ")]), _c('div', {
    staticClass: "btn-box"
  }, [_c('div', {
    staticClass: "btn-level btn-go",
    style: ({
      background: _vm.themeMainColor(_vm.theme).main
    }, 'line-height:35px'),
    on: {
      "click": function ($event) {
        return _vm.openUrl(_vm.taskInfo);
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$lang("Play")))])]), _vm.showError ? _c('div', {
    staticClass: "energy__threetitle"
  }, [_vm._v(" Please stay active in the game long enough ")]) : _vm._e(), _vm.taskInfo.limit_count > _vm.taskInfo.completion_count ? _c('div', {
    staticClass: "btn-level",
    style: !_vm.taskInfo.completed ? {
      background: _vm.themeMainColor(_vm.theme).main
    } : 'color: #fff; background: gray; color:#fff; font-weight:bold; line-height:35px',
    on: {
      "click": _vm.onSubmit
    }
  }, [!_vm.taskInfo.completed ? [_vm._v(" " + _vm._s(_vm.$lang("Claim rewards")) + " ")] : [_vm._v("Next time to receive the reward " + _vm._s(_vm.lastTimeStr))]], 2) : _vm._e()])]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };