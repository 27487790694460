var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "airdropTap page",
    class: _vm.isMobile && 'wap',
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/task-detail-bg.jpg`)})`
    }
  }, [_c('div', {
    staticClass: "top-box",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/airdrop-top-bg.png`)})`
    }
  }, [_c('div', {
    staticClass: "top-box-title"
  }, [[_vm._v(" " + _vm._s(_vm.$lang('AIRDROP'))), _c('span', {
    staticClass: "top-box-title2"
  }, [_vm._v(_vm._s(_vm.$lang("Round 2")))])]], 2), _vm._m(0)]), _c('div', {
    staticClass: "fs"
  }, [_c('div', {
    staticClass: "fs-title"
  }, [_vm._v(_vm._s(_vm.$lang('Core indicators')))]), _c('div', {
    staticClass: "fs-list"
  }, [_c('div', {
    staticClass: "fs-item",
    on: {
      "click": function ($event) {
        return _vm.onTip(0);
      }
    }
  }, [_c('div', {
    staticClass: "fs-item-border"
  }, [_c('div', {
    staticClass: "bg",
    style: {
      width: _vm.progressList[0].progress + '%'
    }
  }), _c('div', {
    staticClass: "item-box"
  }, [_c('div', {
    staticClass: "item-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/airdrop-tap-r2-icon10.png`)
    }
  })]), _vm._m(1), _c('div', {
    staticClass: "item-extend"
  }, [_vm._v("   ")])])])]), _c('div', {
    staticClass: "fs-item",
    on: {
      "click": function ($event) {
        return _vm.onTip(2);
      }
    }
  }, [_c('div', {
    staticClass: "fs-item-border"
  }, [_c('div', {
    staticClass: "bg",
    style: {
      width: _vm.progressList[2].progress + '%'
    }
  }), _c('div', {
    staticClass: "item-box"
  }, [_c('div', {
    staticClass: "item-icon item-icon-3"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/airdrop-tap-r2-icon3.png`)
    }
  })]), _vm._m(2), _c('div', {
    staticClass: "item-extend"
  }, [_vm._v("   ")])])])]), _c('div', {
    staticClass: "fs-item",
    on: {
      "click": function ($event) {
        return _vm.onTip(3);
      }
    }
  }, [_c('div', {
    staticClass: "fs-item-border"
  }, [_c('div', {
    staticClass: "bg",
    style: {
      width: _vm.progressList[3].progress + '%'
    }
  }), _c('div', {
    staticClass: "item-box"
  }, [_c('div', {
    staticClass: "item-icon item-icon-4"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/airdrop-tap-r2-icon5.png`)
    }
  })]), _vm._m(3), _c('div', {
    staticClass: "item-extend"
  }, [_vm.progressList[3].isOk ? [_vm._v(" " + _vm._s(_vm._f("nFormatter")(_vm.userData.app_center_tickets || 0, 2)) + " ")] : [_vm._v("   ")]], 2)])])]), _c('div', {
    staticClass: "fs-item",
    on: {
      "click": function ($event) {
        return _vm.onTip(4);
      }
    }
  }, [_c('div', {
    staticClass: "fs-item-border"
  }, [_c('div', {
    staticClass: "bg",
    style: {
      width: _vm.progressList[4].progress + '%'
    }
  }), _c('div', {
    staticClass: "item-box"
  }, [_c('div', {
    staticClass: "item-icon item-icon-5"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/airdrop-tap-r2-icon6.png`)
    }
  })]), _vm._m(4), _c('div', {
    staticClass: "item-extend"
  }, [_vm.progressList[4].isOk ? [_vm._v(" " + _vm._s(_vm._f("nFormatter")(_vm.userData.extra_invite_count || 0, 2)) + " ")] : [_vm._v("   ")]], 2)])])]), _c('div', {
    staticClass: "fs-item",
    on: {
      "click": function ($event) {
        return _vm.onTip(5);
      }
    }
  }, [_c('div', {
    staticClass: "fs-item-border"
  }, [_c('div', {
    staticClass: "bg",
    style: {
      width: _vm.progressList[5].progress + '%'
    }
  }), _c('div', {
    staticClass: "item-box"
  }, [_c('div', {
    staticClass: "item-icon item-icon-6"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/airdrop-tap-r2-icon9.png`)
    }
  })]), _vm._m(5), _c('div', {
    staticClass: "item-extend"
  }, [_vm.progressList[5].isOk ? [_vm._v(" " + _vm._s(_vm._f("nFormatter")(_vm.hourEearnings || 0, 2)) + " ")] : [_vm._v("   ")]], 2)])])]), _c('div', {
    staticClass: "fs-item",
    on: {
      "click": function ($event) {
        return _vm.onTip(6);
      }
    }
  }, [_c('div', {
    staticClass: "fs-item-border"
  }, [_c('div', {
    staticClass: "bg",
    style: {
      width: _vm.progressList[6].progress + '%'
    }
  }), _c('div', {
    staticClass: "item-box"
  }, [_c('div', {
    staticClass: "item-icon item-icon-6"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/airdrop-tap-r2-icon4.png`)
    }
  })]), _vm._m(6), _c('div', {
    staticClass: "item-extend"
  }, [_vm.progressList[6].isOk ? [_vm._v(" " + _vm._s(_vm._f("nFormatter")(_vm.userData.total_stock || 0, 2)) + " ")] : [_vm._v("   ")]], 2)])])])])])]), _c('div', {
    staticClass: "mMenu"
  }, [_c('m-menu')], 1), _c('pop-airdrop-tap-tip', {
    attrs: {
      "show": _vm.popAirdropTapTipShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popAirdropTapTipShow = $event;
      }
    }
  }), _c('pop-airdrop-tap-logs', {
    attrs: {
      "show": _vm.popAirdropTapLogsShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popAirdropTapLogsShow = $event;
      }
    }
  }), _c('pop-face-tip', {
    attrs: {
      "show": _vm.popFaceTipShow,
      "closeModel": false,
      "showClose": false
    },
    on: {
      "update:show": function ($event) {
        _vm.popFaceTipShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "top-box-num"
  }, [_c('img', {
    attrs: {
      "src": "https://static.tapcoins.app/icons/tap.png"
    }
  }), _c('span', [_vm._v("COMING SOON")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "item-info"
  }, [_c('div', {
    staticClass: "item-label"
  }, [_vm._v("Wallet and Ton chain")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "item-info"
  }, [_c('div', {
    staticClass: "item-label"
  }, [_vm._v("Exchanges")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "item-info"
  }, [_c('div', {
    staticClass: "item-label"
  }, [_vm._v("APP Center")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "item-info"
  }, [_c('div', {
    staticClass: "item-label"
  }, [_vm._v("Invite Friends")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "item-info"
  }, [_c('div', {
    staticClass: "item-label"
  }, [_vm._v("Profit per hour")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "item-info"
  }, [_c('div', {
    staticClass: "item-label"
  }, [_vm._v("KEYS")])]);
}];
export { render, staticRenderFns };