var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "width": "90%",
      "height": "auto",
      "is-loc": "center"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main-face"
  }, [_c('div', {
    staticClass: "adv-title"
  }), _c('div', {
    staticClass: "adv-content"
  }, [_c('img', {
    attrs: {
      "src": "https://static.tapcoins.app/taskimg/wallet_adv01.png",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "btn-confirm",
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm._v(" I am not a bot ")])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };