var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    class: [_vm.isMobile && 'wap', _vm.theme],
    attrs: {
      "id": "app"
    }
  }, [_c('div', {
    staticClass: "main"
  }, [_c('div', {
    attrs: {
      "id": "ton-connect"
    }
  }), _c('div', {
    attrs: {
      "id": "okx-ton-connect"
    }
  }), !_vm.isLoading ? _c('div', {
    staticClass: "loading"
  }, [['tapcoin', 'goldcoin'].includes(_vm.theme) ? [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/loading.gif`)
    }
  })] : _vm.theme == 'reflection' ? [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/loading.webp`),
      "width": "96px"
    }
  })] : [_vm._m(0)]], 2) : _vm._e(), _vm.isLoading && _vm.isRouterAlive ? [_c('layout')] : _vm._e()], 2), _c('div', {
    ref: "coinRef",
    staticClass: "getCoinAnim-coinRef",
    attrs: {
      "id": "coinRef"
    }
  }), _c('div', {
    ref: "destoryCoinContainerRef",
    staticClass: "getCoinAnim-container",
    attrs: {
      "id": "destoryCoinContainerRef"
    }
  })]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "breeding-rhombus-spinner"
  }, [_c('div', {
    staticClass: "rhombus child-1"
  }), _c('div', {
    staticClass: "rhombus child-2"
  }), _c('div', {
    staticClass: "rhombus child-3"
  }), _c('div', {
    staticClass: "rhombus child-4"
  }), _c('div', {
    staticClass: "rhombus child-5"
  }), _c('div', {
    staticClass: "rhombus child-6"
  }), _c('div', {
    staticClass: "rhombus child-7"
  }), _c('div', {
    staticClass: "rhombus child-8"
  }), _c('div', {
    staticClass: "rhombus big"
  })]);
}];
export { render, staticRenderFns };