const stop = body => body.style.overflow = "hidden";
const move = body => body.style.overflow = ""; //出现滚动条
export default {
  name: "popDialog",
  props: {
    show: Boolean,
    closeModel: {
      default: true,
      type: Boolean
    },
    //是否可以通过点击 modal 关闭 Dialog
    modal: {
      default: true,
      type: Boolean
    },
    width: {
      type: String,
      default: "90%"
    },
    isLoc: {
      type: String,
      default: "center"
    },
    showClose: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    show(bool) {
      this.$nextTick(() => {
        const body = document.querySelector("body");
        const pop = document.getElementById("pop");
        if (bool) {
          stop(body);
        } else {
          move(body);
        }
        if (pop.append) {
          pop.append(this.$el);
        } else {
          pop.appendChild(this.$el);
        }
      });
    }
  }
};