import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
import Vue from "vue";
export default {
  name: "bioMatrixScanDetail",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting']),
    ...userVuex.mapState(["userData"])
  },
  data() {
    return {
      result: ''
    };
  },
  beforeRouteLeave(to, from, next) {
    this.$nextTick(() => {
      next();
    });
  },
  watch: {},
  async mounted() {
    FS.init({
      keyid: 'hpfda4504ffeeb9a55',
      //授权key
      tgId: this.userData.tg_id,
      //用户tgid
      dev: false
    });
  },
  methods: {
    openIF() {
      FS.scan();
    },
    async checkSF() {
      let apikey = '3ee5ef68eabdda55aa29a4e3e7b7191d';
      this.result = 'checking';
      this.result = await Vue.prototype.$http.post("https://face.humanpass.net/face/check", {
        apikey,
        "uid": this.userData.tg_id
      });
    },
    onClose() {
      this.WebApp.close();
    }
  }
};