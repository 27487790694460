import "core-js/modules/es.array.push.js";
let sUserAgent = navigator.userAgent.toLowerCase();
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
import { getLevelName } from "@utils/index";
import loadConfig from "@Web3WalletConfig/config";
const squadVueX = createNamespacedHelpers("squad");
const mineVuex = createNamespacedHelpers("mine");
import vailcode from "@utils/errcode";
import cookie from "js-cookie";
export default {
  name: "tapcoinIndex",
  inject: ["reload"],
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...userVuex.mapState(["turbo_temp_times", "robotBox", "userData", "turboNum", "totalCoin", "isICountUp", "loginReward"]),
    ...mineVuex.mapState(["hourEearnings"]),
    coinRef() {
      return this.$refs.coinRef;
    },
    getLevelName() {
      return getLevelName;
    },
    isRobot() {
      return this.robotBox.robot && this.robotBox.coin > 0;
    },
    isIos() {
      return /ipad|iphone/.test(sUserAgent);
    },
    enableConfirm() {
      return this.isBeforeDestroyRequest && !this.isRequestCollect;
    }
  },
  watch: {
    ...squadVueX.mapActions(["squadDetail"]),
    isShowTurboScreen: {
      handler(bool) {
        if (bool) {
          this.blockAnimList = [];
          this.setTurboTempTimes(this.userData.turbo_temp_times);
        } else {
          this.setTurboTempTimes(0);
        }
      },
      // deep: true,
      immediate: true
    },
    userData: {
      async handler(newVal, oldVal) {
        this.progressBar = Math.floor(this.userData.power * 100 / this.userData.power_max);
      },
      immediate: true,
      deep: true
    },
    loginReward: {
      async handler(newVal, oldVal) {
        if (this.loginReward.reward > 0) {
          this.popLoginRewardShow = true;
        }
      },
      immediate: true,
      deep: true
    },
    "userData.coin": {
      handler(newVal, oldVal) {
        setTimeout(() => {
          const textElement = document.getElementById("coinText");
          if (!textElement) return;
          const textWidth = textElement.getBBox().width; // 获取文本的实际宽度
          const svgElement = document.getElementById("coinSvg");
          if (!svgElement) return;
          svgElement.setAttribute("width", textWidth); // 设置SVG宽度
        }, 100);
      },
      immediate: true,
      deep: true
    },
    "userData.turbo_temp": {
      handler(val) {
        if (val > 0) {
          this.showTurboIcon();
        }
      },
      deep: true,
      immediate: true
    },
    isRobot: {
      handler(bool) {
        console.log("boooooo", bool);
        this.popRobotShow = bool ? true : false;
      },
      immediate: true,
      deep: true
    },
    popRobotShow(bool) {
      if (!bool) {
        console.log("popRobotShow", bool);
        this.setRobotBox({
          robot: false,
          coin: 0
        });
      }
    },
    isRequestCollect(bool) {
      if (bool) {
        this.requireCoinCollect();
      }
    },
    //数据未提交时 关闭小程序时进行拦截
    enableConfirm: {
      async handler(bool) {
        if (bool) {
          await this.WebApp.enableClosingConfirmation();
        } else {
          this.WebApp.disableClosingConfirmation();
        }
      },
      deep: true
    }
  },
  data() {
    return {
      progressBar: 0,
      caclTimeVal: null,
      plusTime: null,
      blockAnimList: [],
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "",
        startVal: 0
      },
      delay: 0,
      level: 0,
      popRobotShow: false,
      isRequestCollect: false,
      coinClickNumber: 0,
      isBeforeDestroyRequest: false,
      showTurboIconTimer: null,
      isShowTurboIcon: false,
      isShowTurboScreen: false,
      showTurboScreenTimer: false,
      popLoginRewardShow: false,
      popAdvTipShow: false,
      popRewardResultShow: false,
      avatarAnimOption: {
        scale: 1,
        x: 0,
        timeout: null
      },
      isProgressInfo: false,
      isAvatarLight: false,
      centerScale: 1,
      isX: true,
      animationRunning: true
    };
  },
  async mounted() {
    const devicePixelRatio = window.devicePixelRatio;
    if (devicePixelRatio <= 1 || devicePixelRatio > 2) {
      this.centerScale = 1.2;
    } else if (devicePixelRatio === 2) {
      this.centerScale = devicePixelRatio / 2;
    }
    this.setWowAnim();
    if (this.userData) this.progressBar = Math.floor(this.userData.power * 100 / this.userData.power_max);
    this.setLevel();
    console.log("sdfsdfsd", this.WebApp);
    const advTip = cookie.get("advTip:" + this.userData.id + ":" + (this.setting.popadv.id || 0));
    if (this.setting.popadv && this.setting.popadv.id > 0 && !advTip) {
      this.popAdvTipShow = true;
    }
  },
  beforeDestroy() {
    if (this.isBeforeDestroyRequest) {
      this.requireCoinCollect();
    }
    this.blockAnimList = [];
    clearInterval(this.caclTimeVal);
    this.caclTimeVal = null;
    clearTimeout(this.plusTime);
    this.plusTime = null;
    this.showTurboIconTimer = null;
    this.isShowTurboIcon = false;
    clearTimeout(this.showTurboScreenTimer);
    this.showTurboScreenTimer = null;
    this.WebApp.disableClosingConfirmation();
  },
  methods: {
    ...userVuex.mapMutations(["setTurboTempTimes", "setRobotBox", "setUserData", "setTotalCoin", "setIsICountUp"]),
    ...userVuex.mapActions(["collectCoinData"]),
    ...mineVuex.mapActions(["getHourEarnings"]),
    setLevel() {
      if (this.level >= loadConfig.level.length - 1) {
        this.level = loadConfig.level.length - 1;
        // this.isMaxLevel = true;
      }
    },

    onSkip(page, id) {
      clearTimeout(this.plusTime);
      if (page.indexOf("t.me") !== -1) {
        this.WebApp.openTelegramLink(page);
      } else {
        if (page == "squad") {
          this.$router.push(`/${page}?id=${id}`);
          return;
        }
        if (page == "lucky") {
          if (this.setting.lucky_code_id) this.$router.push(`/youtube?id=${this.setting.lucky_code_id}`);
          return;
        }
        this.$router.push(`/${page}`);
      }
    },
    //金币动画
    coinAnim() {
      if (!this.coinRef) return;
      this.coinRef.style.transition = " all 0.2s ease-in-out";
      const ratio = 2;
      const maxX = 45;
      this.isAvatarLight = true;
      console.log("this.avatarAnimOption.scale", this.avatarAnimOption.scale, ratio, this.avatarAnimOption.scale >= ratio);
      if (this.avatarAnimOption.scale >= ratio) {
        this.avatarAnimOption.scale = ratio;
        this.avatarAnimOption.x = maxX;
        this.coinRef.style.transform = `scale(${ratio}) translateX(${this.avatarAnimOption.x})`;
        this.animationRunning = false;
        return;
      }
      setTimeout(() => {
        this.isAvatarLight = false;
      }, 100);
      this.avatarAnimOption.scale += 0.02;
      if (this.avatarAnimOption.x >= maxX) {
        this.avatarAnimOption.x = maxX;
      } else {
        this.avatarAnimOption.x += 1;
      }
      // console.log("this.avatarAnimOption.scale*20", Number(this.avatarAnimOption.scale)*20);

      this.coinRef.style.transform = `translateX(-${this.avatarAnimOption.x}px) scale(${this.avatarAnimOption.scale})`;
      this.animationRunning = false;
    },
    //漂浮动画
    async floatAnim(e) {
      const event = e;
      const target = e.target;
      const coinListId = document.getElementById("coinList");
      let coinListRect = coinListId.getBoundingClientRect();
      const userData = this.userData;
      if (!this.coinRef) return;
      let x = null;
      let y = null;
      if (!this.isMobile) {
        x = event.x;
        y = event.y;
      } else {
        x = e.touches[0].clientX;
        y = e.touches[0].clientY;
      }
      const damageNumber = document.createElement("div");
      damageNumber.classList.add("coinFlat");
      damageNumber.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" width="400" height="200">
        <text class="coinFlatText">
          <tspan
              dx="0"
              dy="0"
              alignment-baseline="text-before-edge"
              text-anchor="start"
              fill=${this.turbo_temp_times <= 0 ? "#fff" : "#ffec79"}
          >
              +${this.turbo_temp_times <= 0 ? this.userData.multi_tap : this.userData.multi_tap * this.turbo_temp_times}
          </tspan>
        </text>
      </svg>
      `;
      coinListId.appendChild(damageNumber);
      damageNumber.style.left = `${x}px`;
      damageNumber.style.top = `${y - coinListRect.top - 20}px`;
      damageNumber.style.opacity = 1;
      damageNumber.style.transformOrigin = "left top";
      await new Promise(resolve => {
        setTimeout(() => {
          damageNumber.style.top = `${y - coinListRect.top - 120}px`;
          damageNumber.style.opacity = 0;
          damageNumber.style.transform = `scale(1.6)`;
          setTimeout(() => {
            resolve();
          }, 1500);
        }, 150);
      });
      coinListId.removeChild(damageNumber);
    },
    //收集动画
    collectAnim(e) {
      this.coinAnim();
      if (this.userData.power >= this.userData.multi_tap) {
        this.floatAnim(e);
      } else {
        this.isProgressInfo = true;
      }
    },
    //加速操作
    // turboFn(e) {
    //   this.turboLoc(e);
    // },
    //加速按钮随机出现
    turboLoc() {
      this.$nextTick(() => {
        const coinRef = this.$refs.coinRef;
        const turboRef = this.$refs.turboRef;
        if (!turboRef && !coinRef) return;
        const random = Math.random();
        turboRef.style.left = coinRef.offsetWidth * random + "px";
        turboRef.style.top = 70 + random * coinRef.offsetHeight + "px";
      });
    },
    async requireCoinCollect() {
      let rs = await this.$http.post("/coin/collect", {
        coin: this.totalCoin,
        power: this.userData.power,
        turbo: this.turbo_temp_times > 0 ? 1 : 0
      });
      vailcode(rs, () => {
        this.setTotalCoin(0);
        let newUserData = {
          ...this.userData,
          ...(rs.data.userInfo ? rs.data.userInfo : rs.data)
        };
        this.setUserData(newUserData);
        this.setRobotBox({
          robot: rs.data.robot,
          coin: rs.data.coin
        });
      }, () => {});
    },
    calcCollect() {
      this.isBeforeDestroyRequest = true;
      if (this.turbo_temp_times <= 0) {
        if (this.userData.power - this.userData.multi_tap < 0) {
          // this.userData.power = 0
        } else {
          this.userData.coin += this.userData.multi_tap;
          this.userData.power -= this.userData.multi_tap;
          this.setTotalCoin(this.totalCoin + this.userData.multi_tap);
        }
      } else {
        this.userData.coin += this.userData.multi_tap * this.turbo_temp_times;
        this.setTotalCoin(this.totalCoin + this.userData.multi_tap * this.turbo_temp_times);
      }
      clearInterval(this.caclTimeVal);
      this.isRequestCollect = false;
      this.WebApp.enableClosingConfirmation();
      this.coinClickNumber = 0;
      this.caclTimeVal = setInterval(() => {
        this.coinClickNumber++;
        this.isRequestCollect = this.coinClickNumber >= 2 ? true : false;
      }, 2000);
    },
    handleDownClick(e) {
      this.calcCollect();
      this.collectAnim(e);
    },
    async handleUpClick() {
      if (!this.coinRef) return;
      this.isProgressInfo = false;
      let lastTime = performance.now(); // 记录上一次的时间
      this.animationRunning = true; // 控制动画的标志

      const animate = () => {
        if (!this.animationRunning) return; // 如果动画标志为 false，则停止递归调用
        this.coinRef.style.transition = "all 0.6s ease-in-out";
        const currentTime = performance.now();
        // 100ms 的延迟控制
        if (currentTime - lastTime >= 300) {
          lastTime = currentTime;
          this.avatarAnimOption.scale = 1;
          this.avatarAnimOption.x = 0;
          this.isAvatarLight = false;
          this.coinRef.style.transform = `scale(${this.avatarAnimOption.scale})  translateX(${this.avatarAnimOption.x})`;
          // if (this.avatarAnimOption.scale <= 1) {
          // } else {
          //   this.avatarAnimOption.scale -= 0.02; // 缩放比例减小
          //   this.coinRef.style.transform = `scale(${this.avatarAnimOption.scale})`;

          //   // 继续下一帧
          //   requestAnimationFrame(animate);
          // }
        } else {
          // 继续下一帧
          requestAnimationFrame(animate);
        }
      };

      // 启动动画
      requestAnimationFrame(animate);
    },
    showTurboIcon() {
      this.isShowTurboIcon = true;
      this.turboLoc();
      this.showTurboIconTimer = setTimeout(() => {
        clearTimeout(this.showTurboIconTimer);
        this.showTurboIconTimer = null;
        this.isShowTurboIcon = false;
      }, 10000);
    },
    onShowTurboScreen() {
      this.isShowTurboScreen = true;
      this.isShowTurboIcon = false;
      this.showTurboScreenTimer = setTimeout(() => {
        clearTimeout(this.showTurboScreenTimer);
        this.showTurboScreenTimer = null;
        let _timer = setTimeout(async () => {
          this.isShowTurboScreen = false;
          clearTimeout(_timer);
          _timer = null;
          await this.requireCoinCollect();
        }, 100);
      }, 10000);
    }
  }
};