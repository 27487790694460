import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
export default {
  name: "popFaceTip",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting']),
    ...userVuex.mapState(["userData"])
  },
  data() {
    return {};
  },
  props: {},
  watch: {
    "$parent.popFaceTipShow": {
      handler(newVal, oldVal) {
        if (!newVal && typeof oldVal !== 'undefined') {
          this.$parent.popFaceTipShow = false;
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    async onSubmit() {
      this.$parent.popFaceTipShow = false;
      this.$nextTick(() => {
        this.$router.push('/bioMatrixScan');
      });
    }
  }
};