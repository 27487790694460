var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "walletDetail page",
    class: _vm.isMobile && 'wap',
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/task-detail-bg.jpg`)})`
    }
  }, [_c('div', {
    staticClass: "top-box"
  }, [_c('img', {
    attrs: {
      "src": _vm.assetsInfo.icon
    }
  }), _c('div', {
    staticClass: "top-title"
  }, [_vm._v(" " + _vm._s(_vm._f("strToUpperCase")(_vm.assetsInfo.name)) + " ")]), _c('div', {
    staticClass: "top-num"
  }, [_vm._v(" " + _vm._s(_vm.assetsInfo.balance) + " ")])]), _c('div', {
    staticClass: "btn-box"
  }, [_c('button', {
    staticClass: "btn",
    class: !_vm.assetsInfo.withdrawable && 'btn-d',
    on: {
      "click": function ($event) {
        _vm.assetsInfo.withdrawable && _vm.onWithdraw(_vm.chainsInfo, _vm.assetsInfo);
      }
    }
  }, [_vm._v(" Withdraw "), !_vm.assetsInfo.withdrawable ? _c('div', {
    staticClass: "tip"
  }, [_vm._v("SOON")]) : _vm._e()]), _c('button', {
    staticClass: "btn",
    class: 'btn-d'
  }, [_vm._v(" Deposit "), !_vm.assetsInfo.depositable ? _c('div', {
    staticClass: "tip"
  }, [_vm._v("SOON")]) : _vm._e()])]), _c('div', {
    staticClass: "detail-box"
  }, [_vm._m(0), _c('div', {
    staticClass: "detail-list"
  }, [_c('div', {
    staticClass: "detail-header"
  }, [_c('ul', [_c('li', {
    staticClass: "detail-time"
  }, [_vm._v(" Time ")]), _c('li', {
    staticClass: "detail-amount"
  }, [_vm._v(" $" + _vm._s(_vm._f("strToUpperCase")(_vm.assetsInfo.name)) + " ")]), _c('li', {
    staticClass: "detail-remarks"
  }, [_vm._v(" Remarks ")])])]), _c('div', {
    staticClass: "detail-body"
  }, _vm._l(_vm.assetsDetail, function (item, index) {
    return _c('ul', {
      key: index
    }, [_c('li', {
      staticClass: "detail-time"
    }, [_vm._v(" " + _vm._s(item.created_at.replace("T", " ").replace(".000Z", "").replaceAll("-", ".").substring(0, 16)) + " ")]), _c('li', {
      staticClass: "detail-amount"
    }, [_c('span', {
      class: item.direction == 'out' && 'out'
    }, [item.direction == 'in' ? [_vm._v("+")] : [_vm._v("-")], _vm._v(" " + _vm._s(_vm._f("nFormatter")(item.amount, 2)) + " ")], 2)]), _c('li', {
      staticClass: "detail-remarks"
    }, [_vm._v(" " + _vm._s(item.reason) + " ")])]);
  }), 0)])])]), _c('pop-face-tip', {
    attrs: {
      "show": _vm.popFaceTipShow,
      "closeModel": false,
      "showClose": false
    },
    on: {
      "update:show": function ($event) {
        _vm.popFaceTipShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "detail-title"
  }, [_c('div', [_vm._v("Details")])]);
}];
export { render, staticRenderFns };