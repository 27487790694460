var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.show ? _c('div', {
    staticClass: "pop-dialog"
  }, [_vm.modal ? _c('div', {
    staticClass: "shadow",
    on: {
      "click": function ($event) {
        _vm.closeModel && _vm.$emit('update:show', false);
      }
    }
  }) : _vm._e(), _c('div', {
    staticClass: "dialog-main",
    class: [_vm.isLoc === 'center' && 'center', _vm.isLoc === 'bottom' && 'bottom'],
    style: {
      width: _vm.width
    }
  }, [_vm.showClose ? _c('div', {
    staticClass: "btn-close",
    on: {
      "click": function ($event) {
        return _vm.$emit('update:show', false);
      }
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-close"
    }
  })], 1) : _vm._e(), _vm._t("default")], 2), _vm._t("footer")], 2) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };