var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "tapcoinIndex",
    class: _vm.isMobile && 'wap'
  }, [_c('n-head'), _c('div', {
    staticClass: "index-body page-body"
  }, [_c('div', {
    staticClass: "index-main page-main"
  }, [_c('div', {
    staticClass: "bg",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/new/avatar/index-bg-${_vm.userData.hasOwnProperty('skin') && _vm.userData.skin ? _vm.userData.skin : 'basic'}.jpg`)})`
    }
  }), _c('div', {
    staticClass: "top"
  }, [_c('div', {
    staticClass: "c-btns"
  }, [_c('div', {
    staticClass: "btn wow zoomIn",
    attrs: {
      "data-wow-duration": "0.5s"
    },
    on: {
      "click": function ($event) {
        return _vm.onSkip('sign');
      }
    }
  }, [_c('div', {
    staticClass: "bor"
  }), _c('div', {
    staticClass: "btn-main"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/new/nav-icon-login.png`)
    }
  })]), _c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.$lang("Daily Login")))])])]), _c('div', {
    staticClass: "btn wow zoomIn",
    class: !_vm.setting.lucky_code_id && 'dis',
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('div', {
    staticClass: "bor"
  }), _c('div', {
    staticClass: "btn-main"
  }, [_c('div', {
    staticClass: "icon",
    on: {
      "click": function ($event) {
        return _vm.onSkip('lucky');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/new/nav-icon-code.png`)
    }
  })]), _c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.$lang("Lucky Code")))]), _c('div', {
    staticClass: "tip"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/30tip.png`)
    }
  })])])]), _c('div', {
    staticClass: "btn wow zoomIn",
    attrs: {
      "data-wow-duration": "0.5s"
    },
    on: {
      "click": function ($event) {
        return _vm.onSkip('mine');
      }
    }
  }, [_c('div', {
    staticClass: "bor"
  }), _c('div', {
    staticClass: "btn-main"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/new/nav-icon-bounty.png`)
    }
  })]), _c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.$lang("Daily Bounty")))])])]), _c('div', {
    staticClass: "btn wow zoomIn ach",
    attrs: {
      "data-wow-duration": "0.5s"
    },
    on: {
      "click": function ($event) {
        return _vm.onSkip('achievements');
      }
    }
  }, [_c('div', {
    staticClass: "bor"
  }), _c('div', {
    staticClass: "btn-main"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/new/nav-icon-achievements.png`)
    }
  })]), _c('div', {
    staticClass: "label"
  }, [_vm._v("Achievements")])]), _c('div', {
    staticClass: "hot",
    style: {
      background: `url(${require(`@images/${_vm.theme}/new/hot.png`)}) center/auto 100% no-repeat`
    }
  })])]), _c('div', {
    staticClass: "total wow zoomIn",
    attrs: {
      "data-wow-duration": "0.5s"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return (() => {}).apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "icon",
    style: [{
      backgroundImage: `url(${require(`@images/${_vm.theme}/new/icon-gold-1.png`)})`
    }, {
      transform: `translateY(${_vm.centerScale <= 1 ? '6px' : '0'})`
    }]
  }), _c('div', {
    staticClass: "total__value"
  }, [_vm.userData.hasOwnProperty('coin') && _vm.userData.coin !== null && _vm.userData.coin !== undefined ? _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "100%",
      "id": "coinSvg"
    }
  }, [_c('text', {
    attrs: {
      "id": "coinText"
    }
  }, [_c('tspan', {
    attrs: {
      "dx": "0",
      "dy": "0",
      "alignment-baseline": "text-before-edge",
      "text-anchor": "start",
      "fill": "#fff"
    }
  }, [_vm._v(_vm._s(_vm._f("toThousands")(_vm.userData.coin)))])])]) : _vm._e()])])]), _c('div', {
    staticClass: "center",
    style: {
      transform: `translateY(-50%) scale(${_vm.centerScale})`
    },
    on: {
      "touchstart": function ($event) {
        _vm.isMobile && _vm.handleDownClick($event);
      },
      "touchend": function ($event) {
        _vm.isMobile && _vm.handleUpClick($event);
      },
      "mousedown": function ($event) {
        !_vm.isMobile && _vm.handleDownClick($event);
      },
      "mouseup": function ($event) {
        !_vm.isMobile && _vm.handleUpClick($event);
      }
    }
  }, [_c('div', {
    staticClass: "coinList",
    attrs: {
      "id": "coinList"
    }
  }), _c('div', {
    staticClass: "center-main"
  }, [_c('div', {
    staticClass: "center-bg wow pulse",
    style: {
      background: `url(${require(`@images/${_vm.theme}/new/index-bg-gold.png`)}) center/auto 100% no-repeat`
    },
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }), _c('div', {
    staticClass: "avatar"
  }, [_c('div', {
    ref: "coinRef",
    staticClass: "avatar-main wow bounce",
    class: _vm.isAvatarLight && 'active',
    style: {
      background: `url(${require(`@images/${_vm.theme}/new/avatar/avatar-${_vm.userData.avatar_url || 1}.png`)}) center/auto 100% no-repeat`
    },
    attrs: {
      "data-wow-duration": "0.8s"
    }
  })])])]), _c('div', {
    staticClass: "progress"
  }, [_c('div', {
    staticClass: "progress-main wow fadeInLeft",
    class: _vm.isProgressInfo && 'active',
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/new/index-icon-progress.png`),
      "alt": ""
    }
  }), _vm._v(_vm._s(_vm.userData.power) + "/" + _vm._s(_vm.userData.power_max) + " ")]), _c('div', {
    staticClass: "btns wow fadeInRight",
    attrs: {
      "data-wow-duration": "0.5s"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return (() => {}).apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.onSkip('frens');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/new/index-icon-frens.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$lang("Frens")) + " ")]), _c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.onSkip('boost');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/new/index-icon-boosts.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$lang("Boosts")) + " ")])])]), _c('div', {
    staticClass: "mMenu"
  }, [_c('m-menu')], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowTurboIcon,
      expression: "isShowTurboIcon"
    }],
    ref: "turboRef",
    staticClass: "turbo",
    on: {
      "touchstart": function ($event) {
        _vm.isMobile && _vm.isShowTurboIcon && _vm.onShowTurboScreen();
      },
      "click": function ($event) {
        $event.stopPropagation();
        !_vm.isMobile && _vm.isShowTurboIcon && _vm.onShowTurboScreen();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/turbo.png`),
      "alt": ""
    }
  })])])]), void 0, _c('pop-robot', {
    attrs: {
      "show": _vm.popRobotShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popRobotShow = $event;
      }
    }
  }), _c('pop-adv-tip', {
    attrs: {
      "show": _vm.popAdvTipShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popAdvTipShow = $event;
      }
    }
  }), _c('pop-login-reward', {
    attrs: {
      "show": _vm.popLoginRewardShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popLoginRewardShow = $event;
      }
    }
  })], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };